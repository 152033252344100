import React from 'react'
import styles from './Values.module.scss'

interface Props {
  
}

const Values = (props: Props) => {
  return (
    <div className={styles.content}>
      {/* <div className={styles.logo} /> */}
      <h1 className={styles.valuesTitle}>
        <span className={styles.bold}>Better Choices</span> is an examination of Representative Democracy in the United States of America.
      </h1>
      <p className={styles.coreValuesHeader}>
        It is built on<span className={styles.bold}> three core values</span>
      </p>
      <div className={styles.valuesList}>
        <div className={styles.value}>
          <p className={styles.number}>
            1
          </p>
          <p className={styles.valueText}>
            Representative Democracy is the best form of government
          </p>
        </div>
        <div className={styles.value}>
          <p className={styles.number}>
            2
          </p>
          <p className={styles.valueText}>
            The purpose of an election is to choose leaders who best represent the values of the voters
          </p>
        </div>
        <div className={styles.value}>
          <p className={styles.number}>
            3
          </p>
          <p className={styles.valueText}>
            We must use science, mathematics, and simulation to reform our process to produce such leaders
          </p>
        </div>
      </div>
    </div>
  )
}

export default Values