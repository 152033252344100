import {JsonCodable, JsonData} from '../types/Json'

export default class Checkpoint implements JsonCodable {
  title: string = ''
  text: string = ''
  size: number = 0.0
  link: string = ''
  associatedIdentifier: number = 0

  constructor(data: JsonData) {
    this.decode(data)
  }

  encode = (): JsonData => {
    return {
      title: this.title,
      text: this.text,
      start: this.size,
      identifier: this.associatedIdentifier,
      link: this.link
    }
  }

  decode = (data: JsonData) => {
    this.title = data['title']
    this.text = data['text']
    this.size = Number(data['size'])
    this.associatedIdentifier = data['identifier']
    this.link = data['link']
  }
}