import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'
import Main from '../pages/Main/Main'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-163992937-1')

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">  
         <Main />
      </div>
    </Router>
  );
}

export default App;
