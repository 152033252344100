export enum SectionType {
  Scrolling = 0,
  Slider,
  Static,
  Basic
}

export const sectionTypeFromString = (sectionTypeString: string): SectionType => {
  switch (sectionTypeString) {
    case "slider":
      return SectionType.Slider
    case "scrolling":
      return SectionType.Scrolling
    case "basic":
      return SectionType.Basic
    default:
      return SectionType.Static
  }
}

export const stringFromSectionType = (sectionType: SectionType): string => {
  switch (sectionType) {
    case SectionType.Slider:
      return 'slider'
    case SectionType.Scrolling:
      return 'scrolling'
    case SectionType.Basic:
      return 'basic'
    default:
      return 'static'
  }
}