import CheckpointViewState from './CheckpointViewState'
import Section from '../../../../models/Section'
import Checkpoint from '../../../../models/Checkpoint'

export default class CheckpointStates {

  section: Section

  private stateMap: Map<Checkpoint, CheckpointViewState>

  constructor(section: Section, linkPrefix: string) {
    this.section = section
    let state: Map<Checkpoint, CheckpointViewState> = new Map()
    section.progress.checkpoints.forEach((checkpoint: Checkpoint, index: number) => {
      checkpoint.link = `${linkPrefix}${checkpoint.link}`
      state.set(checkpoint, index === 0 ? CheckpointViewState.Active : CheckpointViewState.Unviewed)
    })
    this.stateMap = state
  }

  setActiveCheckpoint = (checkpoint: Checkpoint) => {
    let newMap: Map<Checkpoint, CheckpointViewState> = new Map()
    this.stateMap.forEach((value: CheckpointViewState, key: Checkpoint) => {
      if (key === checkpoint) {
        newMap.set(key, CheckpointViewState.Active)
      }
      else if (value === CheckpointViewState.Active) {
        newMap.set(key, CheckpointViewState.Viewed)
      }
      else {
        newMap.set(key, value)
      }
    })
    this.stateMap = newMap
  }

  activeCheckpoint = () : Checkpoint | undefined => {
    for (let [checkpoint, checkpointState] of this.stateMap) {
      if (checkpointState === CheckpointViewState.Active) {
        return checkpoint
      }
    }
    return undefined
  }

  fullStateMap = () : Map<Checkpoint, CheckpointViewState> => {
    // Shallow copy
    let newMap: Map<Checkpoint, CheckpointViewState> = new Map()
    this.stateMap.forEach((value: CheckpointViewState, key: Checkpoint) => {
       newMap.set(key, value)
    })
    return newMap
  }
}