import {JsonCodable, JsonData, JsonArray} from '../types/Json'
import Section from './Section'

export default class Chapter implements JsonCodable {
  title: string = ''
  sections: Section[] = []

  static decode = (data: JsonData) => {
    let chapter = new Chapter()
    chapter.decode(data)
    return chapter
  }

  encode = (): JsonData => {
    return {
      title: this.title,
      sections: this.sections.map((section) => { return section.encode() })
    }
  }

  decode = (data: JsonData) => {
    this.title = data['title']
    const dataSections = data['sections'] as JsonArray
    this.sections = dataSections.map((sectionObject: JsonData) => {
      const section = new Section()
      section.decode(sectionObject)
      return section
    })
  }

}