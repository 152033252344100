import React from 'react'

import CheckpointViewState from '../CheckpointViewState'
import Checkpoint from '../../../../../models/Checkpoint'

import styles from './CheckpointIndicatorSection.module.scss'

import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'

import ReactGA from 'react-ga'

interface Props {
  viewState: CheckpointViewState
  progressStart: number
  progressSize: number
  checkpointName?: string
  checkpoint: Checkpoint
  onClick: (checkpoint: Checkpoint) => void
}

interface State {

}

export default class CheckpointIndicatorSection extends React.Component<Props, State> {
  render = () => {
    const {viewState, progressStart, progressSize, checkpointName, onClick, checkpoint} = this.props
    let viewClass
    switch (viewState) {
      case CheckpointViewState.Active:
        viewClass = styles.active
        break
      case CheckpointViewState.Viewed:
        viewClass = styles.viewed
        break
      case CheckpointViewState.Unviewed:
        viewClass = styles.unviewed
        break
    }

    const borderColor = "#9F9F9F"

    return (
      <Link to={checkpoint.link}>
      <div data-tip={checkpointName} style={{
        position: 'absolute',
        left: (progressStart * 100) + '%',
        width: (progressSize * 100) + '%',
        height: '100%',
        borderLeft: `1px solid ${borderColor}`,
        borderRight: `1px solid ${borderColor}`,
        borderBottom: `1px solid ${borderColor}`,
        display: 'block'
      }} className={viewClass} onClick={() => {
        ReactGA.event({
          category: 'Navigation',
          action: 'Clicked Progress Section',
          label: checkpoint.title
        })
        onClick(checkpoint)}
      }>
        <ReactTooltip place='bottom' type='dark' />
      </div>
      </Link>
    )
  }
}