import React from 'react'
import Checkpoint from '../../../../../models/Checkpoint'
import styles from './CheckpointLink.module.scss'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

interface Props {
  checkpoint: Checkpoint
  isNext: boolean
}

interface State {

}

export default class CheckpointLink extends React.Component <Props, State> {
  render = () => {
    const { checkpoint, isNext } = this.props
    const arrowClass = isNext ? styles.rightArrow : styles.leftArrow
    const arrowStyle = {
      order: isNext ? 1 : 0
    } as React.CSSProperties
    const textStyle = {
      order: isNext ? 0 : 1
    } as React.CSSProperties
    return (
      <Link to={checkpoint.link} onClick={() => {
        ReactGA.event({
          category: 'Navigation',
          action: 'Clicked Next/Previous Link',
          label: checkpoint.title
        })
      }}>
        <div className={styles.checkpointLinkContent}>
          <div style={arrowStyle} className={[styles.arrow, arrowClass].join(' ')} />
          <p style={textStyle}>
            {checkpoint.title}
          </p>
        </div>
      </Link>
    )
  }
}