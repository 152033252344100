import React from 'react'

import styles from './Main.module.scss'

import YAML from 'yaml'

import SectionView from './SectionView/SectionView'

import Chapter from '../../../models/Chapter'
import Checkpoint from '../../../models/Checkpoint'
import ReferenceText from '../../../models/ReferenceText'

import {JsonData} from '../../../types/Json'

import {Route, Switch} from 'react-router-dom'

import CheckpointStates from './SectionView/CheckpointStates'
import CheckpointIndicator from './SectionView/indicator/CheckpointIndicator'

import ReactGA from 'react-ga'

import ExternalView from './ExternalView'

import "react-responsive-modal/styles.css"
import {Modal} from "react-responsive-modal"

import Intro from '../intro/Intro'

// Import all possible yaml files here:
import config from '../../../config/config.yml';
import configTwo from '../../../config/config-2.yml'
import configIRV from '../../../config/config-irv.yml'


interface Props {

}

interface State {
  showIntro: boolean
  chapters: Chapter[]
  references: ReferenceText[]
  checkpointStates: CheckpointStates
  showModal: boolean
  modalUrl?: string
  linkPath: string
}

export default class Main extends React.Component<Props, State> {

  dataStory?: PolarizationDataStory

  constructor(props: Props) {
    super(props)
    const url = new URL(window.location.href)
    const path = url.pathname
    const components = path.split('/')
    let configData = config
    let linkPath = ""
    if (path.length > 1 && components.length > 1) {
      let name = components[1]
      switch (name) {
        case 'IRV':
          configData = configIRV
          linkPath = '/IRV'
          break
        case 'second':
          configData = configTwo
          linkPath = '/second'
          break
        default:
          break // no op
      }
    }
    const json = YAML.parse(configData) as JsonData
    let chaptersArray = json['chapters']
    const chapters = chaptersArray.map((chapterJson: JsonData) => {
      return Chapter.decode(chapterJson)
    })

    const firstChapter = chapters[0]
    const firstSection = (firstChapter.sections)[0]

    const referenceTextArray = json['references']
    const references = referenceTextArray.map((referenceJson: JsonData) => {
      return new ReferenceText(referenceJson)
    })
    
    this.state = {
      chapters: chapters,
      references: references,
      checkpointStates: new CheckpointStates(firstSection, linkPath),
      showModal: false,
      showIntro: false,
      linkPath: linkPath
    }
  }

  referenceTextForIdentifier = (identifier: string): ReferenceText | null => {
    const {references} = this.state
    let reference: ReferenceText | null = null
    for (var i = 0; i < references.length; ++i) {
      const referenceText = references[i]
      if (referenceText.referenceIdentifier === identifier) {
        reference = referenceText
        break
      }
    }
    return reference
  }

  componentDidMount = () => {
    if (this.dataStory === undefined) {
      this.dataStory = new PolarizationDataStory("visualization", "visualization-overlay", this.openExternalLink)
      const checkpoint = this.state.checkpointStates.activeCheckpoint()
      if (checkpoint === undefined) {
        return
      }

      this.dataStory.update(checkpoint.associatedIdentifier, 0)
    }
    
    const showIntro = () => {
      localStorage.setItem("last-modal-presentation", (new Date()).toISOString())
      this.setState({showIntro: true})
    }
    
    const lastPresentation = localStorage.getItem("last-modal-presentation")
    if (lastPresentation === null) {
      showIntro()
    }
    else {
      const now = new Date()
      const presentationDate = new Date(lastPresentation)
      const elapsedSeconds = now.getTime() / 1000 - presentationDate.getTime() / 1000
      if (elapsedSeconds > (60 * 60 * 24)) {
        showIntro()
      }
    }
  }

  showModal = () => {
    this.setState({
      showModal: true
    })
  }

  hideModal = () => {
    this.setState({
      showModal: false
    })
  }

  openExternalLink = (url: string) => {
    ReactGA.pageview(url)
    this.setState({
      showModal: true,
      modalUrl: url
    })
  }

  render = () => {
    const { checkpointStates, showModal, modalUrl, showIntro } = this.state
    const checkpoint = checkpointStates.activeCheckpoint()
    
    const modalStyle = {
      modal: {
        width: "90vw",
        maxWidth: "100vw",
        height: "90vh",
        margin: "0px auto"
      }
    }

    if (checkpoint === undefined) {
      return null
    }
    
    console.log(checkpoint.link)
    
    return (
        <div className={styles.mainContent}>
          <div className={styles.content} id={"text-content"}>
            {showIntro &&
              <Intro onDone={() => {this.setState({showIntro: false})}} />
            }
            <Modal styles={modalStyle} open={showModal} onClose={this.hideModal}>
              <ExternalView url={modalUrl}/>
            </Modal>
            <div className={styles.sectionContent}>
              <Switch>
                {checkpointStates.section.progress.checkpoints.map((mappedCheckpoint: Checkpoint) => {
                  return (
                      <Route path={mappedCheckpoint.link} key={mappedCheckpoint.link} render={() => {
                        checkpointStates.setActiveCheckpoint(mappedCheckpoint)
                        const dataStory = this.dataStory
                        if (dataStory !== undefined) {
                          dataStory.update(mappedCheckpoint.associatedIdentifier, 0)
                        }
                        ReactGA.pageview(mappedCheckpoint.link)
                        return (
                            <SectionView checkpointStates={checkpointStates}
                                         activeCheckpoint={mappedCheckpoint}/>
                        )
                      }}>
                      </Route>
                  )
                })}
                <Route path={this.state.linkPath} key={checkpoint.link} render={() => {
                  checkpointStates.setActiveCheckpoint(checkpoint)
                  return <SectionView checkpointStates={checkpointStates} activeCheckpoint={checkpoint}/>
                }}>
                </Route>
              </Switch>
              <CheckpointIndicator checkpointStates={checkpointStates}/>
            </div>
          </div>
          <div className={styles.graphContent}>
            <div className={styles.visualizationOverlay} id="visualization-overlay">

            </div>
            <div className={styles.visualization} id="visualization"/>
          </div>
          <div className={styles.showIntro} onClick={() => {
            localStorage.setItem("last-modal-presentation", (new Date()).toISOString())
            this.setState({showIntro: true})
          }}>
            <div className={styles.questionMark}>?</div>
          </div>
        </div>

    )
  }
}