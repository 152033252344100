import React from 'react'

import CheckpointStates from '../CheckpointStates'
import Checkpoint from '../../../../../models/Checkpoint'

import CheckpointIndicatorSection from './CheckpointIndicatorSection'

import styles from './CheckpointIndicator.module.scss'

import {
  withRouter,
  RouteComponentProps
} from 'react-router-dom'

const ArrowRight = 39
const ArrowLeft = 37

interface Props {
  checkpointStates: CheckpointStates
}

interface State {

}

class CheckpointIndicator extends React.Component<Props & RouteComponentProps, State> {

  constructor(props) {
    super(props)
    this.handleKeyDown.bind(this)
    window.addEventListener('keydown', this.handleKeyDown)
  }

  private heightPercentForCheckpoint(checkpoint: Checkpoint) {
    const section = this.props.checkpointStates.section
    const next = section.progress.checkpointAfter(checkpoint)
    const currentProgress = section.progress.progressStartForCheckpoint(checkpoint)
    const nextProgress = next === undefined ? 
      1 : section.progress.progressStartForCheckpoint(next) - currentProgress
    const distance = nextProgress - currentProgress
    return distance
  }

  private goToNextSection = () => {
    const { checkpointStates } = this.props
    const section = this.props.checkpointStates.section
    const activeCheckpoint = checkpointStates.activeCheckpoint()
    if (activeCheckpoint === undefined) {
      return
    }

    const next = section.progress.checkpointAfter(activeCheckpoint)
    if (next === undefined) {
      return
    }
    this.props.history.push(next.link)
  }

  private goToPreviousSection = () => {
    const { checkpointStates } = this.props
    const section = this.props.checkpointStates.section
    const activeCheckpoint = checkpointStates.activeCheckpoint()
    if (activeCheckpoint === undefined) {
      return
    }

    const before = section.progress.checkpointBefore(activeCheckpoint)
    if (before === undefined) {
      return
    }
    this.props.history.push(before.link)
  }

  private handleKeyDown = (event: Event) => {
    if (event['keyCode'] === ArrowRight) {
      this.goToNextSection()
    }
    else if (event['keyCode'] === ArrowLeft) {
      this.goToPreviousSection()
    }
  }

  render = () => {
    const {checkpointStates} = this.props
    const section = checkpointStates.section
    const keys: Checkpoint[] = Array.from(checkpointStates.fullStateMap().keys())
    let progress = 0
    return (
      <div className={styles.indicatorContent}>
        {keys.map((checkpoint: Checkpoint, index: number) => {
          const size = section.progress.sizeForCheckpoint(checkpoint)
          const result = <CheckpointIndicatorSection 
            progressSize={size}
            progressStart={progress}
            checkpointName={checkpoint.title}
            viewState={checkpointStates.fullStateMap().get(checkpoint)!}
            key={index}
            checkpoint={checkpoint}
            onClick={(checkpoint: Checkpoint) => {
              
            }}
          />
          progress += size
          return result
        })}
      </div>
    )
  }
}

export default withRouter(CheckpointIndicator)