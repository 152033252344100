import {JsonCodable, JsonData} from '../types/Json'

export default class ReferenceText implements JsonCodable {
  referenceIdentifier: string = ''
  text: string = ''

  constructor(data: JsonData) {
    this.decode(data)
  }

  encode = (): JsonData => {
    return {
      identifier: this.referenceIdentifier,
      text: this.text,
    }
  }

  decode = (data: JsonData) => {
    this.referenceIdentifier = data['identifier']
    this.text = data['text']
  }
}