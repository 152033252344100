import React from 'react'
import styles from './ExternalView.module.scss'

interface Props {
  url?: string
}

interface State {

}

export default class ExternalView extends React.Component<Props, State> {
  render = () => {
    const {url} = this.props
    console.log(url)
    return (
      <div className={styles.content}>
        {url !== undefined &&
          <iframe title="Better Choices - Information Modal" frameBorder="0" className={styles.iframe} src={url} />
        }
      </div>
    )
  }
}