import React from 'react'
import Checkpoint from '../../../../models/Checkpoint'
import CheckpointStates from './CheckpointStates'

import CheckpointLink from './link/CheckpointLink'

import styles from './SectionView.module.scss'

interface Props {
  checkpointStates: CheckpointStates
  activeCheckpoint: Checkpoint
}

interface State {

}

export default class SectionView extends React.Component <Props, State> {

  render = () => {
    const {checkpointStates, activeCheckpoint} = this.props
    checkpointStates.setActiveCheckpoint(activeCheckpoint)
    const checkpoint = checkpointStates.activeCheckpoint()
    if (checkpoint === undefined) {
      return null
    }

    const nextCheckpoint = checkpointStates.section.progress.checkpointAfter(checkpoint)
    const previousCheckpoint = checkpointStates.section.progress.checkpointBefore(checkpoint)

    const style = {
        width: '95%',
        padding: '0px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '24px'
      } as React.CSSProperties
    return (
      <div className={styles.sectionContent}>
        <div className={styles.navLinks}>
          {previousCheckpoint !== undefined &&
            <CheckpointLink checkpoint={previousCheckpoint} isNext={false} />
          }
          {previousCheckpoint === undefined &&
            <div />
          }

          {nextCheckpoint !== undefined &&
            <CheckpointLink checkpoint={nextCheckpoint} isNext={true} />
          }
        </div>
        <div className={styles.checkpointContent} style={style}>
          <h2>{checkpoint.title}</h2>
          <p>{checkpoint.text}</p>
        </div>
      </div>
    )
  }
}