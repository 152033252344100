import {JsonCodable, JsonData} from '../types/Json'
import SectionProgress from './SectionProgress'
import {SectionType} from '../types/SectionType'

export default class Section implements JsonCodable {
  title: string = ''
  subtitle: string = ''
  imagePath: string = ''

  progress: SectionProgress = new SectionProgress({
    type: SectionType.Static,
    checkpoints: [{
      text: "Default value -- initialize with real data",
      title: "Title",
      progress: 0.0,
      associatedIdentifier: 0
    }]
  })

  encode = (): JsonData => {
    return {
      title: this.title,
      subtitle: this.subtitle,
      progress: this.progress ? this.progress.encode() : null,
      thumbnail: this.imagePath
    }
  }

  decode = (data: JsonData) => {
    this.title = data['title']
    this.subtitle = data['subtitle']
    this.imagePath = data['thumbnail']
    const progressData = data['progress']
    if (progressData !== undefined) {
      this.progress = new SectionProgress(progressData)
    }
  }

}