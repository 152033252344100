import {SectionType, stringFromSectionType, sectionTypeFromString} from '../types/SectionType'
import Checkpoint from './Checkpoint'
import {JsonCodable, JsonData} from '../types/Json'

interface SectionTypeable {
  type: SectionType
}

interface Checkpointable {
  checkpoints: Checkpoint[]

  checkpointForProgress: ((progress: number) => Checkpoint | undefined)
}

export default class SectionProgress implements JsonCodable, Checkpointable, SectionTypeable {
  type: SectionType = SectionType.Static
  checkpoints: Checkpoint[] = []
  scale?: number

  totalSize: number

  constructor(data: JsonData) {
    this.decode(data)
    let sizeSum = 0
    this.checkpoints.forEach((checkpointToAdd: Checkpoint) => {
      sizeSum += checkpointToAdd.size
    })
    this.totalSize = sizeSum
  }

  progressStartForCheckpoint = (checkpoint: Checkpoint) => {
    let currentProgress = 0
    for (let i=0; i<this.checkpoints.length; ++i) {
      const compare = (this.checkpoints)[i]
      if (checkpoint === compare) {
        break
      }
      currentProgress += this.sizeForCheckpoint(compare)
    }
    return currentProgress
  }

  sizeForCheckpoint = (checkpoint: Checkpoint) => {
    return checkpoint.size / this.totalSize
  }

  checkpointForProgress = (progress: number) => {
    let currentProgress = 0

    let result = (this.checkpoints)[this.checkpoints.length - 1]

    for (let i=0; i<this.checkpoints.length; ++i) {
      const checkpoint = (this.checkpoints)[i]
      currentProgress += this.sizeForCheckpoint(checkpoint)
      if (currentProgress > progress) {
        result = checkpoint
        break
      }
    }

    return result
  }

  checkpointAfter = (checkpoint: Checkpoint): Checkpoint | undefined => {
    const index = this.checkpoints.indexOf(checkpoint)
    if (index === -1 || index >= this.checkpoints.length) {
      return undefined
    }

    return (this.checkpoints)[index + 1]
  }

  checkpointBefore = (checkpoint: Checkpoint): Checkpoint | undefined => {
    const index = this.checkpoints.indexOf(checkpoint)
    if (index === -1 || index === 0) {
      return undefined
    }

    return (this.checkpoints)[index - 1] 
  }

  encode = (): JsonData => {
    return {
      type: stringFromSectionType(this.type),
      checkpoints: this.checkpoints.map((checkpoint: Checkpoint) => {return checkpoint.encode()}),
      scale: this.scale
    }
  }

  decode = (data: JsonData) => {
    this.checkpoints = (data['checkpoints']).map((checkpointData: JsonData) => {
      return new Checkpoint(checkpointData)
    })
    this.type = sectionTypeFromString(data['type'])
    this.scale = data['scale']
  }

}