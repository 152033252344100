import React from 'react'
import styles from './Intro.module.scss'
import Values from './values/Values'

const Fade = require('react-reveal/Fade');

export enum IntroState {
  Values,
  About
}

interface Props {
  onDone: () => void
}

const Intro = (props: Props) => {
  return (
    <Fade duration={300}>
      <div className={styles.content} onClick={props.onDone}>
        <div className={styles.introBody}>
            <Values />
          <div className={styles.getStartedButton} onClick={props.onDone}>
            Get Started →
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Intro